import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, palette, spacing}) => ({
  mainSection: {
    background: palette.background.default,
    zIndex: 2,
  },
  greyBackground: {
    background: palette.background.greyLight,
  },
  borderBottom: {
    borderBottom: `1px solid ${palette.section.border}`,
  },
  noBottomPadding: {
    '& > div': {
      paddingBottom: 0,
    },
  },
  noTopPadding: {
    '& > div': {
      paddingTop: 0,
    },
  },
  mediumPadding: {
    padding: spacing(20, 0),
    [breakpoints.down('sm')]: {
      padding: spacing(7.7, 0),
    },
  },
  smallPadding: {
    padding: spacing(15, 0),
    [breakpoints.down('sm')]: {
      padding: spacing(7.7, 0),
    },
  },
  verySmallPadding: {
    padding: spacing(10, 0),
    [breakpoints.down('sm')]: {
      padding: spacing(10, 0),
    },
  },
  noSidePadding: {
    '& > div': {
      position: 'relative',
      width: '100%!important',
      maxWidth: 'inherit!important',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  smallWidth: {
    '& > div': {
      width: '40% !important',
      margin: 'auto',
      [breakpoints.down('sm')]: {
        width: '90% !important',
        flexDirection: 'column',
      },
    },
  },
  richTextPadding: {
    padding: spacing(7, 0),
  },
}))

export default useStyles
