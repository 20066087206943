import Container from '@material-ui/core/Container'
import React from 'react'

import {AwardsHeaderPropTypes} from 'helpers/propTypes'
import Description from 'components/UI/Description'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const AwardsHeader = ({pageTitle, description, selectedAward}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  const renderDefaultHeader = () => (
    <Section hasPaddingBottom={false} hasVerySmallPadding>
      <Title variant="h1" type="title" isCentered={!!isMobile}>
        {pageTitle}
      </Title>
      <Container className={classes.descriptionContainer}>
        <Description type="largeDescription">{description}</Description>
      </Container>
    </Section>
  )

  const renderSelectedAwardHeader = () => (
    <Section hasVerySmallPadding>
      <div className={classes.awardHeader}>
        <img
          src={selectedAward.logo.file.url}
          alt={`${selectedAward.name} logo`}
        />
        <div className={classes.awardHeaderDescription}>
          <p className={classes.awardName}>{selectedAward.name}</p>
          <p className={classes.awardDescription}>
            {selectedAward.shortDescription.shortDescription}
          </p>
        </div>
      </div>
    </Section>
  )

  return selectedAward ? renderSelectedAwardHeader() : renderDefaultHeader()
}

AwardsHeader.propTypes = AwardsHeaderPropTypes

AwardsHeader.defaultProps = {}

export default AwardsHeader
