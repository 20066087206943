import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({spacing, fontSizes, breakpoints}) => ({
  title: {
    fontSize: fontSizes.title,
    fontWeight: 500,
    fontFamily: 'CamptonMedium',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  descriptionContainer: {
    marginLeft: 0,
    paddingLeft: 0,
    maxWidth: 500,
  },
  descriptionText: {
    fontFamily: 'CamptonLight',
    marginTop: spacing(2.5),
    fontSize: fontSizes.largeDescriptions,
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  awardHeader: {
    display: 'flex',
    '& img': {
      width: '25%',
      height: 250,
      padding: spacing(5),
      objectFit: 'contain',
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& img': {
        width: '100%',
        paddingTop: 0,
      },
    },
  },
  awardHeaderDescription: {
    width: '75%',
    marginLeft: spacing(6),
    [breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%',
    },
  },
  awardName: {
    fontSize: 26,
    fontFamily: 'CamptonMedium',
  },
  awardDescription: {
    fontFamily: 'CamptonLight',
  },
}))

export default useStyles
