import React from 'react'

import {Button} from '@material-ui/core'
import {navigate} from 'gatsby'
import {string} from 'prop-types'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import Section from 'components/UI/Section'

import useStyles from './styles'

const BackButton = ({backToAllAwardsCta}) => {
  const classes = useStyles()

  return (
    <Section hasPaddingBottom={false} hasVerySmallPadding>
      <Button
        className={classes.backButton}
        startIcon={<KeyboardBackspaceIcon />}
        onClick={() => navigate('../about-us/committed-keyrus')}
      >
        {backToAllAwardsCta}
      </Button>
    </Section>
  )
}

BackButton.propTypes = {
  backToAllAwardsCta: string.isRequired,
}

export default BackButton
